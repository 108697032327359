<template>
  <div class="log" style="padding-left: 5px !important">
    <button
      color="orange"
      outline
      class="details-ass-btn"
      type="button"
      v-if="type === 'Finance'"
      @click.stop="dialog = false"
      @click="getList()"
    >
      Details
    </button>
    <button
      type="button"
      color="orange"
      outline
      class="pay-ass-btn green-button"
      v-if="
        priceData.assignmentStatus != 4 &&
        priceData.assignmentStatus != 7 &&
        priceData.isPurchaseInvoiceGenerated &&
        priceData.isReadyForFinalPayment &&
        PendingAmount > 0 &&
        type === 'rbFailed'
      "
      @click.stop="dialog = false"
      @click="getList()"
    >
      {{
        priceData.ownerType == "Transporter" ? "Pay Transporter" : "Pay Driver"
      }}
    </button>

    <button
      type="button"
      color="orange"
      outline
      class="pay-ass-btn green-button"
      v-else-if="
        priceData.assignmentStatus != 4 &&
        priceData.assignmentStatus != 7 &&
        !priceData.isPurchaseInvoiceGenerated &&
        priceData.customerCurrency === 'PKR' &&
        priceData.isReadyForFinalPayment &&
        PendingAmount > 0 &&
        type === 'rbFailed'
      "
      @click.stop="dialog = false"
      @click="getList()"
    >
      {{
        priceData.ownerType == "Transporter" ? "Pay Transporter" : "Pay Driver"
      }}
    </button>
    <v-dialog v-model="dialog" max-width="80%" persistent>
      <v-form ref="form" onsubmit="return false;" class="layout__list">
        <v-card style="padding: 0px !important">
          <v-toolbar
            dark
            style="background-color: #dcdcdc; color: black; padding-left: 10px"
            flat
          >
            <v-toolbar-title
              >Payment Details Of
              {{
                assignDetails.ownerType == "Transporter"
                  ? "Transporter"
                  : " Driver"
              }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn class="mx-2" fab small @click="dialog = false">
              <v-icon dark> close </v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="loading"
          ></v-progress-linear>
          <v-spacer>
            <v-card-text class="nospace">
              <v-card-text class="nospace">
                <v-layout class="pl-3 pt-2">
                  <v-flex xs4 md4>
                    <div class="sub-heading muted">
                      Total Driver Price :
                      <span style="color: black" class="heading"
                        >{{
                          this.assignDetails.paymentAmount
                            ? this.assignDetails.paymentAmount +
                              this.assignDetails.totalAdditionalAmount
                            : 0
                        }}
                        {{ this.assignDetails.customerCurrency }}</span
                      >
                    </div>
                  </v-flex>
                  <v-flex xs4 md4>
                    <div class="sub-heading muted">
                      Driver Price :
                      <span style="color: black" class="heading">
                        {{ this.assignDetails.paymentAmount || "0" }}
                        {{ this.assignDetails.customerCurrency }}</span
                      >
                    </div>
                  </v-flex>
                  <v-flex xs4 md4>
                    <div class="sub-heading muted">
                      Additional Charges :
                      <span style="color: black" class="heading break-word">
                        {{ this.assignDetails.totalAdditionalAmount || "0" }}
                        {{ this.assignDetails.customerCurrency }}</span
                      >
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout class="pl-3">
                  <v-flex xs4 md4>
                    <div class="sub-heading muted break-word">
                      Advance Paid :
                      <span style="color: black" class="heading"
                        >{{ this.assignDetails.totalAdvancePaidAmount || "0" }}
                        {{ this.assignDetails.customerCurrency }}</span
                      >
                    </div>
                  </v-flex>
                  <v-flex xs4 md4>
                    <div class="sub-heading muted break-word">
                      Amount Paid Till Date:
                      <span style="color: black" class="heading"
                        >{{
                          this.assignDetails.amountPaidToDriver +
                            this.assignDetails.totalAdvancePaidAmount || "0"
                        }}
                        {{ this.assignDetails.customerCurrency }}</span
                      >
                    </div>
                  </v-flex>
                  <v-flex xs4 md4>
                    <div class="sub-heading muted break-word">
                      Pending Amount :
                      <span style="color: black" class="heading"
                        >{{ PendingAmount }}
                        {{ this.assignDetails.customerCurrency }}</span
                      >
                    </div>
                  </v-flex>
                </v-layout>
              </v-card-text>

              <v-data-table
                id="TransporterListTable"
                hide-actions
                :headers="
                  assignDetails.ownerType == 'Transporter'
                    ? headersTransporter
                    : headers
                "
                :items="items"
                style="word-break: break-all"
              >
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.amount }} {{ currency }}</td>
                  <td v-if="props.item.updatedAt">
                    {{ props.item.employeeName }}({{
                      props.item.employeeId
                    }})<br />
                    {{ getTime3(props.item.updatedAt) }}
                  </td>

                  <td v-else>
                    {{ props.item.employeeName }}({{ props.item.employeeId }})
                  </td>
                  <td v-if="props.item.paidDate">
                    {{ props.item.employeeName }}({{
                      props.item.employeeId
                    }})<br />
                    {{
                      getDate(props.item.paidDate) + " " + props.item.paidTime
                    }}
                  </td>
                  <td v-else>-</td>
                  <td v-if="assignDetails.ownerType != 'Transporter'">
                    <div v-if="assignDetails.creditorPaymentMode === 'Cash'">
                      {{ assignDetails.creditorPaymentMode }}

                      <span v-if="assignDetails.creditorSwiftNo">
                        <br />
                        Swift No. -
                        {{ assignDetails.creditorSwiftNo || "N.A" }}</span
                      >

                      <span v-if="assignDetails.creditorIBANNumber">
                        <br />
                        IBAN No. -
                        {{ assignDetails.creditorIBANNumber || "N.A" }}</span
                      >
                      <span v-if="assignDetails.creditorAccountNumber">
                        <br />
                        Account No. -
                        {{ assignDetails.creditorAccountNumber || "N.A" }}</span
                      >

                      <span v-if="assignDetails.creditorAccountHolderName">
                        <br />
                        Account Holder Name-
                        {{
                          assignDetails.creditorAccountHolderName || "N.A"
                        }}</span
                      >
                    </div>
                    <div
                      v-else-if="
                        assignDetails.creditorPaymentMode === 'Bank Transfer'
                      "
                    >
                      {{ assignDetails.creditorPaymentMode || "N.A" }}<br />
                      Swift No. -
                      {{ assignDetails.creditorSwiftNo || "N.A" }} <br />
                      IBAN No. -
                      {{ assignDetails.creditorIBANNumber || "N.A" }}<br />
                      Account No. -
                      {{ assignDetails.creditorAccountNumber || "N.A" }}
                      <br />Account Holder Name-
                      {{ assignDetails.creditorAccountHolderName || "N.A" }}
                    </div>
                    <div v-else>-</div>
                  </td>

                  <td>
                    {{ props.item.modeOfPayment || "-" }} <br />{{
                      props.item.bankName
                    }}
                  </td>

                  <td>
                    {{
                      assignDetails.driverFirstName
                        ? assignDetails.driverFirstName +
                          " " +
                          assignDetails.driverLastName
                        : ""
                    }}<br />
                    {{ assignDetails.driverId ? assignDetails.driverId : "" }}
                    <br />
                    <span v-if="assignDetails.truckNumber">
                      <template v-if="assignDetails.truckNumber">
                        {{ assignDetails.truckPrefix }}-{{
                          assignDetails.truckNumber
                        }}</template
                      >
                      <template v-else>
                        {{ assignDetails.driverData.truckPrefix }}-{{
                          assignDetails.driverData.truckNumber
                        }}</template
                      >
                    </span>
                  </td>
                  <td>
                    {{ props.item.paymentStatus }}
                  </td>

                  <td>{{ props.item.comment || "-" }}</td>
                  <!-- 
                  <td>
                    Due To  Rubel(Cfo of trukkin ) we comment this code
                    <EditPendingAmount
                      v-if="type === 'Finance'"
                      @payment-list-refresh="refreshList()"
                      :invoiceCity="invoiceCity"
                      :invoiceCompany="invoiceCompany"
                      :PendingAmount="PendingAmount"
                      :assignDetails="assignDetails"
                      :driverCreditDuration="
                        assignDetails.driverData
                          ? assignDetails.driverData.driverPaymentDuration
                          : null
                      "
                      v-permissions="'pending-amount-for-driver-for-accounts'"
                      :priceData="props.item"
                    /><br />
                    <proof-of-delivery :deliveryProof="props.item.proof" />
                  </td> -->

                  <td v-if="props.item.paymentStatus == 'Failed'">
                    <v-btn
                      :disabled="loading"
                      class="advance-btn-color"
                      style="padding: 0px !important"
                      flat
                      color="white "
                      @click="retryDeleteFinalAmount(props.item._id)"
                    >
                      Delete & Retry
                    </v-btn>
                  </td>
                  <td style=" min-width: 180px; !important " v-else>
                    <!-- <EditPendingAmount
                      v-if="type === 'Finance'"
                      @payment-list-refresh="refreshList()"
                      :invoiceCity="invoiceCity"
                      :invoiceCompany="invoiceCompany"
                      :PendingAmount="PendingAmount"
                      :assignDetails="assignDetails"
                      :driverCreditDuration="
                        assignDetails.driverData
                          ? assignDetails.driverData.driverPaymentDuration
                          : null
                      "
                      v-permissions="'pending-amount-for-driver-for-accounts'"
                      :priceData="props.item"
                    /> -->
                    <div class="pt-1">
                      <proof-of-delivery
                        v-if="
                          props.item.proof &&
                          props.item.proof.length &&
                          !props.item.isRetryPossibleForRbFailure
                        "
                        :deliveryProof="props.item.proof"
                      />
                    </div>
                    <v-btn
                      v-if="props.item.isRetryPossibleForRbFailure"
                      :disabled="loading"
                      class="advance-btn-color"
                      style="padding: 0px !important"
                      flat
                      color="white "
                      @click="retryFinalAmount(props.item._id)"
                    >
                      Retry</v-btn
                    >
                    <div
                      style="
                        color: red;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 10px !important;
                      "
                      v-if="
                        !props.item.isRetryPossibleForRbFailure &&
                        props.item.errorFromRealBooks
                      "
                    >
                      <v-tooltip
                        top
                        content-class="tooltip-op"
                        style="margin: 0 10px 0 0 !important"
                      >
                        <span
                          slot="activator"
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: white !important;
                            background: red !important;
                            padding: 3px !important;
                            border-radius: 100% !important;
                            height: 20px !important;
                            width: 20px !important;
                            text-align: center !important;
                          "
                        >
                          i
                        </span>
                        <span class="tooltip">Connect To Technical team</span>
                      </v-tooltip>
                      <span> *{{ props.item.errorFromRealBooks }}</span>
                    </div>
                  </td>
                </template>
              </v-data-table>

              <span class="page-count-span totalpages"
                >Total Pages - {{ totalPages }}</span
              >

              <PaginationButtons
                v-if="dialog"
                :url="`/dmsAdmin/getPaymentDetailsListing`"
                :payload="payload"
                :callback="callback"
                :componentKey="componentKey"
                v-on:pagination-payload="paginationPayload"
              />
              <success-dialog
                :content="x.message"
                :show="x.success"
                :heading="x.heading"
                :onclose="closeMessage"
              />

              <v-snackbar
                :timeout="3000"
                color="red darken-2"
                v-model="error"
                class="white--text"
                v-if="error"
                >{{ error }}</v-snackbar
              >
            </v-card-text>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import { EventBus } from "../../../event-bus.js";
import Loading from "vue-loading-overlay";
import {
  finalPayList,
  finalPayListTransporter,
} from "@/constants/datatable-headers.js";
import SuccessDialog from "@/components/Common/SuccessDialog";

import ProofOfDelivery from "@/components/Pop-ups/ProofOfDelivery";
import ReadyToPayPopup from "@/components/Pop-ups/ReadyToPayPopup.vue";
import EditPendingAmount from "../Popups/EditPendingAmount.vue";

import ErrorBox from "@/components/Common/ErrorBox";
import moment from "moment";
import {
  StorageKeys,
  JobTypes,
  JobListTypes,
  CancellationType,
} from "../../../constants/constants";
import { async } from "q";
import PaginationButtons from "@/components/Pagination/Pagination-New";
export default {
  created() {
    this.customerStatus = this.$props.viewOnly;
    this.cancellationType = CancellationType;
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.workingCountry = key.value;
        }
      });
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "documents") {
        this.$router.push(`/jobs`);
      }
      this.workingCountry = val.value;
      this.loading = true;
    });
    this.payload = {
      skip: "0",
      limit: "15",
      assignmentId: this.$props.assignId,
      workingCountry: this.workingCountry,
    };
    this.JobTypes = JobTypes;
    if (!navigator.onLine) {
      this.loading = false;
      this.x.error = "Please check your internet connection";
      setTimeout(() => {
        this.x.error = "";
      }, 3000);
      return;
    }
  },
  components: {
    PaginationButtons,
    SuccessDialog,
    ErrorBox,
    Loading,
    EditPendingAmount,
    ProofOfDelivery,
  },
  props: {
    priceData: Object,
    assignId: String,
    currency: String,
    type: String,
    PendingAmount: Number,
  },

  data() {
    return {
      opeartionName: "",
      workingCountry: null,
      rejectDialog: false,
      rejectReason: null,
      assignDetails: {},
      customerStatus: "",
      bankName: "",
      ownerType: "",
      advanceId: null,
      JobTypes: [],
      invoiceCompany: "",
      invoiceCity: "",
      totaldata: "",
      advanceAmount: null,
      advanceStatus: "",
      cancellationType: [],
      cancellationReason: "",
      cancelType: "",
      status: "",
      jobType: "",
      jobId: "",
      assignID: "",
      isRejectTrue: false,
      paymentAmount: null,
      assignmentDetails: {},
      isMarkedAllRead: false,
      error: "",
      valid: true,
      lazy: false,
      cancelDialog: false,
      isCancelAll: null,
      cancelreason: "",
      loading: false,
      dialog: false,
      dialog2: false,
      processing: false,
      x: {
        message: "",
        loading: true,
        success: false,
        menu: false,
        heading: "Final Payment Update",
        error: null,
        missingField: null,
      },
      search: "",
      componentKey: 0,

      totalPages: 0,

      payload: {},

      headers: finalPayList,
      headersTransporter: finalPayListTransporter,
      items: [],
    };
  },
  watch: {},
  methods: {
    getStatus(status) {
      switch (status) {
        case "Paid":
          return "Paid";
        case "Issued":
          return "Requested";
        case "Rejected":
          return "Rejected";
        case "Cancel":
          return "Cancelled";
        case "Ready":
          return "Ready To Pay";
        case "PartiallyPaid":
          return "Partial Paid";

        default:
          return "Unknown";
      }
    },
    getPendingAmount(data) {
      let pendingAmount =
        data.paymentAmount -
        data.totalAdvancePaidAmount -
        data.amountPaidToDriver +
        data.totalAdditionalAmount;

      return Number.isInteger(pendingAmount)
        ? pendingAmount
        : pendingAmount.toFixed(2);
    },
    refreshList() {
      this.$emit("assignment-list-refresh");
      this.componentKey = this.componentKey + 1;
    },

    retryFinalAmount(id) {
      // console.log(this.assignDetails.assignmentId)
      this.loading = true;
      console.log(id);

      let url = "/dmsAdmin/advance/retry-rb-payment";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        driverFinalPaymentId: id,
        action: "finalPaymentToRB",
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.x.message = response.data.data.message;
          this.x.success = true;
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    retryDeleteFinalAmount(id) {
      // console.log(this.assignDetails.assignmentId)
      this.loading = true;
      console.log(id);

      let url = `/dmsAdmin/assignmentPayment/${id}/delete-and-retry`;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {};

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.componentKey = this.componentKey + 1;
          // this.x.message = response.data.data.message;
          // this.x.success = true;
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    getBankName() {
      if (this.invoiceCity == "1" && this.invoiceCompany == "1") {
        this.bankName = "Alinma Bank - Dammam";
      } else if (this.invoiceCity == "2" && this.invoiceCompany == "1") {
        this.bankName = "Alinma Bank - Jeddah";
      } else if (this.invoiceCity == "3" && this.invoiceCompany == "1") {
        this.bankName = "Alinma Bank - Riyadh";
      } else if (this.invoiceCity == "1" && this.invoiceCompany == "2") {
        this.bankName = "Mashreq Bank PSJC";
      } else if (this.invoiceCity == "2" && this.invoiceCompany == "2") {
        this.bankName = "EMIRATES NBD Bank";
      }
      return this.bankName;
    },
    getList() {
      console.log("getlist");
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry = key.value;
          }
        });
      }

      this.dialog = true;
      this.payload = {
        skip: "0",
        limit: "15",
        assignmentId: this.$props.assignId,
        workingCountry: this.workingCountry,
      };
    },
    openReject(id) {
      this.rejectDialog = true;
      this.advanceId = id;
    },
    async check(type, id) {
      this.rejectDialog = true;
      this.advanceId = id;
    },
    closeReject() {
      this.rejectDialog = false;

      this.rejectReason = "";
    },

    rejectAdvance() {
      this.loading = true;

      if (!this.rejectReason) {
        this.error = "Please enter rejection reason before moving ahead";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        this.loading = false;
        return false;
      }

      const data = {
        assignmentId: this.$props.assignId,
        advanceId: this.advanceId,
        comment: this.rejectReason.trim(),
      };
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const { apiUrl } = this.constants;
      this.axios.post(`${apiUrl}/dmsAdmin/rejectAdvance`, data, config).then(
        (response) => {
          const { data } = response.data;
          this.x.success = true;
          this.rejectDialog = false;
          this.x.message =
            "The advance for " +
            this.assignDetails.advanceAmount +
            " " +
            this.currency +
            " " +
            " has been rejected for " +
            this.assignDetails.assignmentId +
            " assignment id";

          this.rejectReason = "";
          this.loading = false;
        },
        (error) => {
          this.x.error = error.response.data.message;
          this.loading = false;
        }
      );
    },
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.$emit("on-block");
      this.$emit("assignment-list-refresh");
    },
    getTime3(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    getDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getJobType(type) {
      switch (type) {
        case "1":
          return "Land";
        case "2":
          return "Air";
        case "3":
          return "Sea";
        default:
          return "Land";
      }
    },
    callback(res) {
      this.loading = false;
      this.assignDetails = res.assignmentDetails[0];
      this.invoiceCity = res.invoicingCity;
      this.invoiceCompany = res.invoicingCompany;
      this.$eventBus.$emit("off-load");
      this.items = res.list;
      this.totalPages = Math.ceil(res.totalData / 15);
      // window.scrollTo(0, 0);
    },

    paginationPayload(event) {
      this.payload = event;
      this.loading = true;
    },
  },
};
</script>
<style lang="scss">
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.dashboard-style {
  color: grey;
  font-size: 16px;
  font-weight: 700;
}
.p-16 {
  padding: 8px !important;
}
.customer-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.document {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #666666;
}
.editCustomer {
  margin: auto 0;
}

.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.color-black {
  color: #000000 !important;
}
.cancel-ass-btn {
  text-decoration: none;
  color: #ffffff;

  border-radius: 5px;
  border: 2px solid red;
  padding: 2px 0px;
}
.reject-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: red;
  border-radius: 5px;
  border: 2px solid red;
  padding: 2px 0px;
}

.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.p-b-0 {
  padding-bottom: 0px !important ;
}
.p-t-0 {
  padding-top: 0px !important ;
}
.text-stylee {
  font-size: 20px;
}
</style>
