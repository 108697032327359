<template>
  <div>
    <SelectCountry
      :open="selectCountryDialog"
      @closeSelectCountry="selectCountryDialog = false"
      :type="'/procurement/new'"
    />
    <v-card class>
      <v-layout>
        <v-flex xs3>
          <v-tabs
            style="margin-bottom: 20px"
            v-model="tabStatusMain"
            fixed-tabs
            background-color="darkorange"
            dark
            class="main__tabs"
          >
            <v-tab :href="`#Jobs`" @click="redirectTo('Jobs')">
              Job View
            </v-tab>
            <v-tab :href="`#Assignment`" @click="redirectTo('Assignment')">
              Assignment View
            </v-tab>
          </v-tabs>
        </v-flex>
        <v-flex xs9>
          <v-tabs
            style="margin-bottom: 20px"
            v-model="tabStatus"
            fixed-tabs
            background-color="darkorange"
            dark
          >
            <v-tab
              :href="`#Unassigned`"
              @click="searchData('Unassigned', null)"
            >
              Unassigned Jobs
              <span v-if="newUnassigned" class="tab-span">{{
                newUnassigned
              }}</span>
            </v-tab>
            <v-tab
              :href="`#In-progress`"
              @click="searchData('In-progress', null)"
            >
              In Progress Jobs
              <span v-if="newInprogress" class="tab-span">{{
                newInprogress
              }}</span>
            </v-tab>
            <v-tab :href="`#Completed`" @click="searchData('Completed', null)">
              Completed Jobs
              <span v-if="newCompleted" class="tab-span">{{
                newCompleted
              }}</span>
            </v-tab>
          </v-tabs>
        </v-flex>
      </v-layout>

      <v-card-title class="bg-clr">
        <v-layout justify-space-around wrap>
          <v-avatar tile color="#ffa500" class="total avatar">
            <span class="white--text breakword">
              Finding Drivers
              <span class="count">{{ totalFindingDrivers || "0" }}</span>
            </span>
          </v-avatar>

          <v-avatar tile color="#ffa500" class="total avatar">
            <span class="white--text breakword">
              Driver Assigned
              <span class="count">{{ totalDriverAssigned || "0" }}</span>
            </span>
          </v-avatar>
          <v-avatar tile color="#ffa500" class="total avatar">
            <span class="white--text breakword">
              Total Jobs
              <span class="count">{{ totalJob || "0" }}</span>
            </span>
          </v-avatar>
          <v-btn
            v-permissions="'add-new-jobs'"
            class="white--text"
            flat
            @click="checkAddJob()"
            color="orange darken-1"
          >
            <v-icon>add</v-icon>&nbsp;Add New Job
          </v-btn>
        </v-layout>
      </v-card-title>
      <v-container fluid>
        <v-layout row pt-3 class="">
          <v-flex xs2 md3>
            <v-select
              v-model="searchTypes"
              :items="searchItems"
              flat
              label="Search Type"
              item-text="status"
              single-line
              class="pt-0 currencyTitle pppp"
              item-value="abbr"
              persistent-hint
              @change="searchData()"
              height="10"
              return-object
              outline
            ></v-select>
          </v-flex>
          <v-flex xs2 md3 pr-3>
            <v-text-field
              height="55px"
              class="gap Bottom_2px"
              label="Search here.."
              outline
              :disabled="!searchTypes"
              single-line
              v-model="search"
              @keyup.enter="searchData()"
              @click:append="searchData()"
              append-icon="search"
            ></v-text-field>
          </v-flex>
          <v-flex xs2 md3>
            <v-select
              v-model="driverSearchTypes"
              :items="driverSearchItems"
              flat
              label="Search  Type"
              item-text="status"
              single-line
              class="pt-0 currencyTitle pppp"
              item-value="abbr"
              persistent-hint
              @change="searchData()"
              height="10"
              return-object
              outline
            ></v-select>
          </v-flex>
          <v-flex xs2 md3 pr-3>
            <v-text-field
              height="55px"
              class="gap Bottom_2px"
              label="Search here.."
              outline
              :disabled="!driverSearchTypes"
              single-line
              v-model="driverSearch"
              @keyup.enter="searchData()"
              @click:append="searchData()"
              append-icon="search"
            ></v-text-field>
          </v-flex>
          <v-flex xs2 pr-1>
            <v-select
              v-model="subJobType"
              :items="JobListTypes"
              label="Job Type"
              persistent-hint
              return-object
              @change="searchData()"
              single-line
            ></v-select>
          </v-flex>
          <v-flex
            xs3
            pl-3
            style="padding-top: 18px"
            id="jobDate"
            class="wrapper"
          >
            <ejs-daterangepicker
              :placeholder="waterMark"
              v-model="dateRange2"
            ></ejs-daterangepicker>
          </v-flex>

          <v-flex xs1 class="reset-btn">
            <v-card-actions class="justify-center">
              <v-btn
                flat
                class="reset-btn-color"
                prepend-icon
                color="orange darken-1"
                @click.native="reset()"
              >
                Reset
                <v-icon color="orange">donut_large</v-icon>
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-text class="nospace">
        <v-data-table
          id="jobsTable"
          hide-actions
          :headers="headers"
          :disable-initial-sort="true"
          :pagination.sync="pagination"
          :items="items"
        >
          <template slot="items" slot-scope="props">
            <tr
              :key="props.index"
              :class="[
                getDates(props.item.startDate, props.item.pickupTime) <= 12 &&
                getDates(props.item.startDate, props.item.pickupTime) > 6 &&
                (props.item.jobStatus === 3 || props.item.jobStatus === 4)
                  ? 'colorchangeyellow'
                  : '',
                getDates(props.item.startDate, props.item.pickupTime) <= 6 &&
                (props.item.jobStatus === 3 || props.item.jobStatus === 4)
                  ? 'colorchange'
                  : '',
              ]"
            >
              <td>
                {{ props.item.jobId }}
                <activityLogs
                  :permissions="'activity-logs-jobs'"
                  :type="'jobs'"
                  :_id="props.item._id"
                  :id="props.item.jobId.toString()"
                />
              </td>
              <td>
                <img
                  v-if="props.item.workingCountry == 'PAK'"
                  src="../../assets/pakflag.png"
                  height="20px"
                /><img
                  v-if="props.item.workingCountry == 'KSA'"
                  src="../../assets/ksaflag.png"
                  height="20px"
                /><img
                  v-if="props.item.workingCountry == 'UAE'"
                  src="../../assets/Uaeflag.png"
                  height="20px"
                /><img
                  v-if="props.item.workingCountry == 'UAE LA'"
                  src="../../assets/Uaeflag.png"
                  height="20px"
                  style="border-radius: 50%"
                /><br />{{ props.item.workingCountry || "-" }}
              </td>

              <td v-if="props.item.customer">
                {{ props.item.companyName || props.item.customer }}
              </td>
              <td v-else>
                {{ props.item.customerEmail || "-" }}
              </td>
              <td>
                {{ getTime3(props.item.pickupDate) }}
                {{ props.item.pickUpTime }}
              </td>
              <td style="width: 10%; word-break: break-all">
                <v-tooltip
                  right
                  class="milestone"
                  content-class="tooltip-op"
                  color="orange"
                >
                  <template slot="activator"
                    >{{ props.item.sourceCity }},
                    {{ props.item.sourceCountry }}</template
                  >
                  <span class="tooltip">{{ props.item.sourceLocation }}</span>
                </v-tooltip>
              </td>
              <td style="width: 10%; word-break: break-all">
                <v-tooltip
                  top
                  class="milestone"
                  content-class="tooltip-op"
                  color="orange"
                >
                  <template slot="activator"
                    >{{ props.item.destinationCity }},
                    {{ props.item.destinationCountry }}</template
                  >
                  <span class="tooltip">{{
                    props.item.destinationLocation
                  }}</span>
                </v-tooltip>
              </td>
              <td v-if="props.item.jobPrice">
                {{ props.item.jobPrice }} {{ props.item.customerCurrency }}
              </td>
              <td v-else>N.A.</td>
              <td v-if="props.item.jobStatus == 6">
                {{ props.item.transit }}/{{
                  props.item.numberOfAssignmentsRequired
                }}
                <br />
                <Assignments
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item.jobId"
                />
              </td>
              <td v-else-if="props.item.jobStatus == 7">
                {{ props.item.shipment }}/{{
                  props.item.numberOfAssignmentsRequired
                }}
                <br />
                <Assignments
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item.jobId"
                />
              </td>
              <td v-else-if="props.item.jobStatus == 8">
                {{ props.item.paymentPending }}/{{
                  props.item.numberOfAssignmentsRequired
                }}
                <br />

                <Assignments
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item.jobId"
                />
              </td>
              <td v-else-if="props.item.jobStatus == 9">
                {{ props.item.paymentDone }}/{{
                  props.item.numberOfAssignmentsRequired
                }}
                <br />
                <Assignments
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item.jobId"
                />
              </td>
              <td
                v-else-if="
                  props.item.jobStatus == 5 ||
                  props.item.jobStatus == 4 ||
                  props.item.jobStatus == 1
                "
              >
                {{ props.item.Assigned }}/{{
                  props.item.numberOfAssignmentsRequired
                }}
                <br />
                <Assignments
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item.jobId"
                />
              </td>
              <td v-else>
                <Assignments
                  v-permissions="'activity-logs-jobs'"
                  :jobId="props.item.jobId"
                />
              </td>

              <td>
                {{ jobsStatus(props.item.jobStatus) }}
                <br />
                {{
                  props.item.isAcknowedged && props.item.procurementExec
                    ? props.item.procurementExec.name
                    : ""
                }}
              </td>

              <td class>
                <button
                  v-permissions="'view-details-jobs'"
                  v-if="!props.item.isAcknowedged && props.item.jobStatus != -1"
                  @click="
                    open(props.item, props.index, props.item.workingCountry)
                  "
                  target="_blank"
                  :disabled="props.item.jobStatus == 10"
                  class="assign-driver-btn"
                  title="Acknowledge Inquiry"
                >
                  Acknowledge
                </button>
                <button
                  v-if="props.item.jobStatus != -1 && props.item.isAcknowedged"
                  v-permissions="'view-details-jobs'"
                  @click="check(props.item.jobId)"
                  :disabled="loading"
                  target="_blank"
                  class="track-ass-btn"
                >
                  Details
                </button>
                <br />
                <button
                  v-if="props.item.jobStatus != -1 && props.item.isAcknowedged"
                  v-permissions="'view-details-jobs'"
                  @click="open2(props.item, props.index)"
                  target="_blank"
                  class="cancel-btn"
                >
                  Cancel
                </button>
              </td>
              <td style="width: 15%">
                <span
                  v-if="
                    props.item.isTransporterJob &&
                    (props.item.jobStatus != -1 || props.item.jobStatus != 2) &&
                    props.item.isAcknowedged
                  "
                  >{{ props.item.jobModel }}</span
                >

                <Bidpopup
                  v-if="
                    props.item.jobPrice &&
                    !props.item.isDriverJob &&
                    !props.item.isTransporterJob &&
                    props.item.jobStatus != -1 &&
                    (props.item.jobStatus == 3 || props.item.jobStatus == 4) &&
                    props.item.isAcknowedged &&
                    isOnlyLand(props.item.jobType)
                  "
                  :address="props.item"
                  @error="
                    x.error =
                      'Job has already started, you cannot send a bid for this job'
                  "
                  v-on:transport-job="bidSuccess"
                  v-on:bid-success="refreshBid"
                />
                <v-spacer></v-spacer>
                <Responsepopup
                  :bid="props.item"
                  v-if="
                    props.item.isTransporterJob &&
                    props.item.jobStatus != -1 &&
                    props.item.jobStatus != 2 &&
                    props.item.isAcknowedged
                  "
                  v-on:response-job="refreshBid"
                />
                <ResponseForDriver
                  :bidD="props.item"
                  v-if="
                    props.item.isDriverJob &&
                    props.item.jobStatus != -1 &&
                    props.item.jobStatus != 2 &&
                    props.item.isAcknowedged
                  "
                  v-on:response-jobD="refreshBid"
                />
              </td>
              <td>
                <div v-if="props.item.LastCommentTime">
                  <v-tooltip
                    left
                    class="milestone"
                    content-class="tooltip-op-comment"
                    color="orange"
                  >
                    <template slot="activator">
                      {{
                        getTime4(props.item.LastCommentTime.updatedAt)
                      }}</template
                    >
                    <span
                      class="tooltip"
                      style="word-break: break-all !important"
                      >{{
                        props.item.LastCommentTime.comment != ""
                          ? props.item.LastCommentTime.comment
                          : "N.A"
                      }}</span
                    >
                  </v-tooltip>
                </div>
                <SendComments
                  :userId="props.item.userId"
                  :userName="props.item.customer"
                  :detail="props.item"
                  :type="2"
                  :id="props.item.jobId"
                  v-on:pagination-load="refreshOnComment"
                />
              </td>
            </tr>
          </template>
          <v-alert
            slot="no-results"
            :value="isNoSearchResult"
            color="blue"
            icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >
        </v-data-table>

        <pagination
          :totalPages="totalPages"
          :pages="this.pages"
          @pageNo="page"
          :type="0"
          :componentKey="componentKey"
          :loading="paginationLoading"
          :url="paginationURL"
          :callback="callback"
          :action="`procure`"
          v-on:pagination-load="checkLoading"
        />
      </v-card-text>
      <v-snackbar
        :timeout="3000"
        color="red darken-2"
        v-model="x.error"
        class="white--text"
        v-if="x.error"
        >{{ x.error }}</v-snackbar
      >
      <v-dialog
        v-model="dialog"
        persistent
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
      >
        <loading :active.sync="loading" />

        <v-card>
          <v-toolbar dark :color="options.color" dense flat>
            <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
          </v-toolbar>

          <v-card-text v-show="!!message" class="pa-4">
            {{ message }}
            <!-- <v-text-field
              type="text"
              label="Please add comments here"
              required
              v-model="statusComments"
              :value="statusComments"
            /> -->
            <!-- {{ userNameList }} -->
            <v-autocomplete
              :items="userNameList"
              item-text="name"
              v-model="selectedUserId"
              label="select the name of admin who procured this job"
              item-value="_id"
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions class="pt-0 pr-4">
            <v-spacer />
            <v-btn
              color="primary darken-1"
              :disabled="loading"
              text
              @click.native="agree"
              >Yes</v-btn
            >
            <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- CANCEL CONFIRMATION -->
      <v-dialog
        v-model="dialog2"
        persistent
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
      >
        <v-card>
          <v-toolbar dark :color="options.color" dense flat>
            <v-toolbar-title class="white--text">{{
              cancelTitle
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text v-show="!!cancelMessage" class="pa-4">
            {{ cancelMessage }}
            <v-flex pt-2 pr-1>
              <div class="subheading muted caption">
                Provide cancellation reason
              </div>
              <v-select
                class="pt-0 currencyTitle"
                v-model="cancelType"
                :items="cancellationType"
                :menu-props="{ offsetY: true }"
                label="Select your option"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </v-flex>
            <v-flex>
              <div
                v-if="cancelType.value == 'Other’s'"
                class="subheading muted caption"
              >
                Reason
              </div>
              <v-text-field
                class="pt-0 currencyTitle"
                v-if="cancelType.value == 'Other’s'"
                type="text"
                single-line
                label="Enter your reason"
                required
                v-model="cancelComments"
                :value="cancelComments"
              />
            </v-flex>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-btn color="primary darken-1" text @click.native="open3"
              >Yes</v-btn
            >
            <v-btn color="grey" text @click.native="cancel2">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialog3"
        persistent
        :max-width="450"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
      >
        <v-card>
          <v-toolbar
            dark
            flat
            style="background-color: #fee9d0; color: black; padding-left: 100px"
          >
            <v-toolbar-title dark>{{ cancelTitle1 }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon
                class="heading grey--text text--darken-4"
                @click.prevent="dialog3 = false"
                >close</v-icon
              >
            </v-btn>
          </v-toolbar>
          <v-spacer class="spacerclass">
            <v-card-text
              style="text-align: center"
              v-show="!!cancelMessage1"
              class="pa-4"
            >
              <div class="pb-4">
                <img
                  src="../../assets/Character-sitting.png"
                  height="300"
                  width="300"
                />
              </div>
              <div class="subheading">{{ cancelMessage1 }}</div>

              <div
                v-if="cancelType.value == 'Other’s'"
                class="subheading muted pt-2"
              >
                <span style="color: darkgrey">Cancellation reason:</span>
                <p style="word-break: break-all">
                  {{ cancelType.value }} - {{ cancelComments }}
                </p>
              </div>
              <div v-else class="subheading muted pt-2">
                <span style="color: darkgrey">Cancellation reason:</span>
                {{ cancelType.value }}
              </div>
            </v-card-text>

            <v-card-actions class="pt-0 pb-5" style="justify-content: center">
              <v-btn
                medium
                style="text-transform: none; !important"
                color="orange white--text"
                darken-1
                text
                :loading="loading"
                @click.native="agree2"
                >DONE</v-btn
              >
            </v-card-actions>
          </v-spacer>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import Vue from "vue";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import SendComments from "@/components/Pop-ups/SendComments";

Vue.use(DateRangePickerPlugin);
//import DateRangePicker from "vue2-daterange-picker";
import activityLogs from "../Common/activityLogs";
//you need to import the CSS manually (in case you want to override it)
//import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { EventBus } from "../../event-bus.js";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
import Bidpopup from "./Bidpopup";
import Responsepopup from "./Responsepopup";

import ResponseForDriver from "./ResponseForDriver";
let momentTz = require("moment-timezone");
import JobsProfile from "@/components/Jobs/JobDetails/JobsProfile";
import DriverProfileForm from "@/components/Drivers/DriverProfileForm";
import PaginationButtons from "@/components/Pagination/PaginationButtons";
import pagination from "@/components/Pagination/pagination";
import Assignments from "./Assignments";
import {
  StorageKeys,
  JobListTypes,
  CancellationType,
} from "../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
import SelectCountry from "../Common/SelectCountry";
import { procurementList } from "@/constants/datatable-headers.js";
import { async } from "q";
import { newjobList } from "../../constants/api-urls";
export default {
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = JSON.stringify(workingCountry);
      this.identify(localStorage.getItem("email"));
      this.track("Procurement Section", {
        email: localStorage.getItem("email"),
        name: localStorage.getItem("user"),
        "Working Country": workingCountry.toString(),
      });
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "procurement") {
        this.workingCountry = JSON.stringify(val);
        if (localStorage.getItem("paginationUrlProcure") !== null) {
          this.updatePaginationUrl();
        } else {
          this.paginationURL = `/dmsAdmin/newjobList?workingCountry=${this.workingCountry}&isSearch=true&status=${this.tabStatus}`;
          this.getJobListCount();
        }
      }
    });
    EventBus.$on("unassignedJob-notification", (val) => {
      this.newUnassigned = this.newUnassigned + 1;
    });
    EventBus.$on("assignedDriver-notification", (val) => {
      this.newInprogress = this.newInprogress + 1;
    });
    EventBus.$on("completeAssignment-notification", (val) => {
      this.newCompleted = this.newCompleted + 1;
    });
    this.JobListTypes = JobListTypes;
    this.cancellationType = CancellationType;
    if (localStorage.getItem("paginationUrlProcure") !== null) {
      this.updatePaginationUrl();
    } else {
      this.paginationURL = `/dmsAdmin/newjobList?workingCountry=${this.workingCountry}&isSearch=true&status=${this.tabStatus}`;
      this.getJobListCount();
    }
    if (localStorage.getItem("subJobTypePro")) {
      this.subJobType = JSON.parse(localStorage.subJobTypePro);

      this.loading = false;
    }
    if (localStorage.getItem("searchProcurement") !== null) {
      this.search = localStorage.searchProcurement;
      this.searchTypes = JSON.parse(localStorage.searchProcurementType);
    }
    if (localStorage.getItem("searchProcurementDriver") !== null) {
      this.driverSearch = localStorage.searchProcurementDriver;
      this.driverSearchTypes = JSON.parse(
        localStorage.searchProcurementDriverType
      );
    }

    if (localStorage.getItem("ProtartdateFilter") !== null) {
      this.dateRange2 = JSON.parse(localStorage.ProtartdateFilter);
    }

    this.$eventBus.$on("close-job-profile", () => {
      this.dialog = false;
      this.currentItem = null;
    });
  },

  mounted() {
    if (localStorage.getItem("TabStatus") != null) {
      this.tabStatus = localStorage.getItem("TabStatus").toString();
      this.searchData(this.tabStatus, null);
    } else {
      this.tabStatus = "Unassigned";
      this.searchData(this.tabStatus, null);
    }
  },

  components: {
    DriverProfileForm,
    ResponseForDriver,
    pagination,
    //DateRangePicker,
    JobsProfile,
    SendComments,
    PaginationButtons,
    Assignments,
    activityLogs,
    ErrorBox,
    Responsepopup,
    Bidpopup,
    SelectCountry,
  },
  data() {
    return {
      componentKey: 0,
      selectCountryDialog: false,
      isDashboard: true,
      waterMark: "Select a Range",
      dateRange2: null,
      dateRange: { startDate: "", endDate: "" },
      workingCountry: null,
      JobListTypes: [],
      operation: "",
      operationName: "",
      driverSearch: "",
      subJobType: "",
      cancellationType: [],
      cancelType: "",
      dateToday: moment(new Date()).format("YYYY/MM/DD HH:mm"),
      pages: 1,
      FilterDateBool: false,
      userNameList: [],
      selects: { status: "", abbr: "" },
      jobsort: [
        { status: "Sort by Job ID", abbr: "JobId" },
        { status: "Sort by Date", abbr: "Date" },
      ],
      rules: {
        // required: value => !!value || "This field is required.",
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        phoneNumber: (v) =>
          /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
          "Please Enter Valid Mobile No.",
        number: (v) => /^[0-9]*$/.test(v) || "Please Enter Valid Price",
      },
      // ----- NOTIFICATION ---
      newUnassigned: 0,
      newInprogress: 0,
      newCompleted: 0,

      title: null,
      message: null,
      dialog: false,
      dialog3: false,
      selectedUserId: "",

      cancelTitle: null,
      cancelMessage: null,
      cancelTitle1: null,
      cancelMessage1: null,
      dialog2: false,
      cancelComments: "",

      options: {
        color: "#ffc04c",
        width: 500,
        zIndex: 200,
      },
      x: {
        error: null,
      },
      currentEnquiry: null,
      currentIndex: null,
      paginationURL: ``,
      startDate: "",
      select: { status: "", abbr: "" },

      status: "",
      tabStatus: "Unassigned",
      tabStatusMain: "Jobs",
      executive: "",

      searchItems: [
        {
          status: "Company Name/Customer Name",
          abbr: "companyName/customerName",
        },
        { status: "Job ID", abbr: "jobId" },
        { status: "Assignment ID", abbr: "assignmentId" },
        // { status: "Driver ID", abbr: "id" },
        // {
        //   status: "Driver Name",
        //   abbr: "name",
        // },
        // { status: "Truck Number", abbr: "truckNumber" },
      ],
      driverSearchItems: [
        {
          status: "Company Name/Customer Name",
          abbr: "companyName/customerName",
        },
        { status: "Job ID", abbr: "jobId" },
        { status: "Assignment ID", abbr: "assignmentId" },
        // { status: "Driver ID", abbr: "id" },
        // {
        //   status: "Driver Name",
        //   abbr: "name",
        // },
        // { status: "Truck Number", abbr: "truckNumber" },
      ],
      driverSearch: "",
      driverSearchTypes: "",
      searchTypes: "",
      search: "",
      totalInquiry: 0,
      totalDriverAssigned: 0,
      totalFindingDrivers: 0,
      inactiveJob: 0,
      completedJob: 0,
      totalJob: 0,
      totalPages: 0,
      jobStatus: "",
      pagination: {},
      isNoSearchResult: false,
      loading: false,
      paginationLoading: false,
      server: false,
      headers: procurementList,
      items: [],
    };
  },
  props: {
    updateList: Number,
  },
  watch: {
    dateRange2(val) {
      if (val) {
        if (val[0] && val[1]) {
          this.startDate = moment(val[0]).format("YYYY-MM-DD");
          this.endDate = moment(val[1]).format("YYYY-MM-DD");
        }
        this.searchData(null, val);
      } else {
        this.startDate = "";
        this.endDate = "";

        localStorage.removeItem("ProenddateFilter");
        localStorage.removeItem("ProtartdateFilter");
        this.searchData(null, val);
      }
    },
    pagination(val) {
      let temp_url = "";
      if (val["sortBy"] === "createdDate") {
        if (val["descending"] === true) {
          temp_url = "&toSort=date&sortBy=ascending";
          this.updateQueryStringParameter("toSort", "date");
          this.updateQueryStringParameter("sortBy", "descending");
        } else if (val["descending"] === false) {
          temp_url = "&toSort=date&sortBy=descending";
          this.updateQueryStringParameter("toSort", "date");
          this.updateQueryStringParameter("sortBy", "ascending");
        }
      }
      if (val["sortBy"] === "quote") {
        if (val["descending"] === true) {
          temp_url = "&toSort=price&sortBy=descending";
          this.updateQueryStringParameter("toSort", "price");
          this.updateQueryStringParameter("sortBy", "descending");
        } else if (val["descending"] === false) {
          temp_url = "&toSort=price&sortBy=ascending";
          this.updateQueryStringParameter("toSort", "price");
          this.updateQueryStringParameter("sortBy", "ascending");
        }
      }
      if (!val["sortBy"]) {
        this.removeParam(["toSort", "sortBy"]);
      }
      this.updateQueryStringParameter("isSearch", "true");
      deep: true;
    },
  },
  methods: {
    redirectTo(type) {
      if (type === "Jobs") {
        this.$router.push(`/procurements`);
      } else {
        this.$router.push(`/assignments`);
      }
    },
    async check(jobId) {
      this.operationName = "view-details-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.$router.push(`/jobs/${jobId}`);
      } else {
        return;
      }
    },
    async checkAddJob() {
      this.operationName = "add-new-jobs";
      let y = await this.checkOpertaionPermission(this.operationName);

      if (y) {
        if (localStorage.getItem("workingCountries")) {
          this.workingCountries = JSON.parse(
            localStorage.getItem("workingCountries")
          );
          let workingCountry = [];
          this.workingCountries.forEach((key, index) => {
            if (key.isSelected) {
              workingCountry.push(key.value);
            }
          });
          if (workingCountry.length === 1) {
            this.$router.push(`/procurement/new`);
          } else {
            this.selectCountryDialog = true;
          }
        }
      } else {
        return;
      }
    },

    removeParam(keys) {
      let sourceURL = localStorage.getItem("paginationUrlProcure");
      var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString =
          sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
      if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
          param = params_arr[i].split("=")[0];
          if (keys.indexOf(param) >= 0) {
            params_arr.splice(i, 1);
          }
        }
        rtn = rtn + "?" + params_arr.join("&");
      }
      this.paginationURL = rtn;
    },
    updateQueryStringParameter(key, value) {
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = this.paginationURL.indexOf("?") !== -1 ? "&" : "?";
      if (this.paginationURL.match(re)) {
        this.paginationURL = this.paginationURL.replace(
          re,
          "$1" + key + "=" + value + "$2"
        );
      } else {
        this.paginationURL = this.paginationURL + separator + key + "=" + value;
      }
    },

    updatePaginationUrl() {
      let tempUrl = localStorage.getItem("paginationUrlProcure");
      let x = tempUrl.split("&");
      let y = x[0].split("=");
      y[1] = this.workingCountry;
      x[0] = y.join("=");
      tempUrl = x.join("&");
      this.paginationURL = tempUrl;
      this.getJobListCount();
    },
    getJobTypeWithCount(type) {
      var stringAir = "";
      var stringSea = "";
      var stringLand = "";
      if (type) {
        var count = {};
        var string = "";
        type.forEach(function (i) {
          count[i] = (count[i] || 0) + 1;
        });
        if (count["2"] > 0) {
          stringAir = "Air-" + count["2"];
        }
        if (count["3"] > 0) {
          stringSea = "Sea-" + count["3"];
        }
        if (count["1"] > 0) {
          stringLand = "Land-" + count["1"];
        }
        if (stringAir.length > 0 || stringSea.length > 0) {
          stringLand = stringLand.length > 0 ? stringLand + ", " : stringLand;
        }
        if (stringSea.length > 0) {
          stringAir = stringAir.length > 0 ? stringAir + ", " : stringAir;
        }
        return stringLand + stringAir + stringSea;
      } else {
        return "Land-1";
      }
    },
    isOnlyLand(type) {
      if (type) {
        var count = {};
        var string = "";
        type.forEach(function (i) {
          count[i] = (count[i] || 0) + 1;
        });
        if (count["1"] > 1 || count["3"] > 0 || count["2"] > 0) {
          return false;
        } else {
          return true;
        }
      }
    },
    getJobType(type) {
      switch (type) {
        case "1":
          return "Land";
        case "2":
          return "Air";
        case "3":
          return "Sea";
        default:
          return "Land";
      }
    },
    getDates(startdate, time) {
      var test = moment.unix(startdate).format("YYYY/MM/DD") + " " + time;

      var diff = Math.abs(new Date(test) - new Date(this.dateToday)); // hours
      var minutes = Math.floor(diff / 1000 / 60);
      var hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      var difference =
        (new Date(test).getTime() - new Date(this.dateToday).getTime()) / 1000;
      difference /= 60 * 60;

      return Math.abs(Math.round(difference));
    },
    page(event) {
      this.pages = event;
    },
    getUserList(assignmentWorkingCountry) {
      const url = `${this.constants.apiUrl}/user/adminList?workingCountry=${assignmentWorkingCountry}`;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.axios.get(url, config).then(
        (response) => {
          console.log(response.data.data);
          this.userNameList.push(...response.data.data);
          console.log(response);
        },
        (error) => {
          // this.x.error = "Failed to Fetch User Details";
        }
      );
    },
    bidSuccess(event) {},
    searchData(jobStatusVal = null, dateRangeVal = null) {
      if (this.searchTypes.abbr) {
        if (localStorage.getItem("TabStatus") == "In-progress" || "Completed") {
          this.driverSearchItems = [
            {
              status: "Company Name/Customer Name",
              abbr: "companyName/customerName",
            },
            { status: "Job ID", abbr: "jobId" },
            { status: "Assignment ID", abbr: "assignmentId" },
            { status: "Driver ID", abbr: "id" },
            {
              status: "Driver Name",
              abbr: "name",
            },
            { status: "Truck Number", abbr: "truckNumber" },
          ];
        }
        if (
          localStorage.getItem("TabStatus") == "Unassigned" ||
          !localStorage.getItem("TabStatus")
        ) {
          this.driverSearchItems = [
            {
              status: "Company Name/Customer Name",
              abbr: "companyName/customerName",
            },
            { status: "Job ID", abbr: "jobId" },
            { status: "Assignment ID", abbr: "assignmentId" },
          ];
        }
        this.driverSearchItems.forEach((k) => {
          if (k["abbr"] == this.searchTypes.abbr) {
            let elementPos = this.driverSearchItems
              .map(function (x) {
                return x.abbr;
              })
              .indexOf(k["abbr"]);

            this.driverSearchItems.splice(elementPos, 1);
          }
        });
      }
      if (this.driverSearchTypes.abbr) {
        if (localStorage.getItem("TabStatus") == "In-progress" || "Completed") {
          this.searchItems = [
            {
              status: "Company Name/Customer Name",
              abbr: "companyName/customerName",
            },
            { status: "Job ID", abbr: "jobId" },
            { status: "Assignment ID", abbr: "assignmentId" },
            { status: "Driver ID", abbr: "id" },
            {
              status: "Driver Name",
              abbr: "name",
            },
            { status: "Truck Number", abbr: "truckNumber" },
          ];
        }
        if (
          localStorage.getItem("TabStatus") == "Unassigned" ||
          !localStorage.getItem("TabStatus")
        ) {
          this.searchItems = [
            {
              status: "Company Name/Customer Name",
              abbr: "companyName/customerName",
            },
            { status: "Job ID", abbr: "jobId" },
            { status: "Assignment ID", abbr: "assignmentId" },
          ];
        }
        this.searchItems.forEach((k) => {
          if (k["abbr"] == this.driverSearchTypes.abbr) {
            let elementPos = this.searchItems
              .map(function (x) {
                return x.abbr;
              })
              .indexOf(k["abbr"]);

            this.searchItems.splice(elementPos, 1);
          }
        });
      }
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }

      let timezone = momentTz.tz.guess(); //not used
      let url = [];
      url.push(`workingCountry=${this.workingCountry}`);

      url.push(`isSearch=true`);

      if (jobStatusVal) {
        if (jobStatusVal == "In-progress" || "Completed") {
          this.searchItems = [
            {
              status: "Company Name/Customer Name",
              abbr: "companyName/customerName",
            },
            { status: "Job ID", abbr: "jobId" },
            { status: "Assignment ID", abbr: "assignmentId" },
            { status: "Driver ID", abbr: "id" },
            {
              status: "Driver Name",
              abbr: "name",
            },
            { status: "Truck Number", abbr: "truckNumber" },
          ];
          this.driverSearchItems = [
            {
              status: "Company Name/Customer Name",
              abbr: "companyName/customerName",
            },
            { status: "Job ID", abbr: "jobId" },
            { status: "Assignment ID", abbr: "assignmentId" },
            { status: "Driver ID", abbr: "id" },
            {
              status: "Driver Name",
              abbr: "name",
            },
            { status: "Truck Number", abbr: "truckNumber" },
          ];
          this.driverSearchItems.forEach((k) => {
            if (k["abbr"] == this.searchTypes.abbr) {
              let elementPos = this.driverSearchItems
                .map(function (x) {
                  return x.abbr;
                })
                .indexOf(k["abbr"]);

              this.driverSearchItems.splice(elementPos, 1);
            }
          });
          this.searchItems.forEach((k) => {
            if (k["abbr"] == this.driverSearchTypes.abbr) {
              let elementPos = this.searchItems
                .map(function (x) {
                  return x.abbr;
                })
                .indexOf(k["abbr"]);

              this.searchItems.splice(elementPos, 1);
            }
          });
        }
        if (jobStatusVal == "Unassigned") {
          this.searchItems = [
            {
              status: "Company Name/Customer Name",
              abbr: "companyName/customerName",
            },
            { status: "Job ID", abbr: "jobId" },
            { status: "Assignment ID", abbr: "assignmentId" },
          ];
          this.driverSearchItems = [
            {
              status: "Company Name/Customer Name",
              abbr: "companyName/customerName",
            },
            { status: "Job ID", abbr: "jobId" },
            { status: "Assignment ID", abbr: "assignmentId" },
          ];
          this.driverSearchItems.forEach((k) => {
            if (k["abbr"] == this.searchTypes.abbr) {
              let elementPos = this.driverSearchItems
                .map(function (x) {
                  return x.abbr;
                })
                .indexOf(k["abbr"]);

              this.driverSearchItems.splice(elementPos, 1);
            }
          });
          this.searchItems.forEach((k) => {
            if (k["abbr"] == this.driverSearchTypes.abbr) {
              let elementPos = this.searchItems
                .map(function (x) {
                  return x.abbr;
                })
                .indexOf(k["abbr"]);

              this.searchItems.splice(elementPos, 1);
            }
          });
        }
        this.paginationURL = "";
        this.newUnassigned = 0;
        this.newInprogress = 0;
        this.newCompleted = 0;
        localStorage.setItem("TabStatus", jobStatusVal);
        this.tabStatus = jobStatusVal;
        url.push(`status=${jobStatusVal}`);
      } else {
        this.jobStatus = this.tabStatus;
        url.push(`status=${this.tabStatus}`);
      }

      // if (
      //   this.search == localStorage.getItem("searchProcurement") ||
      //   this.driverSearch == localStorage.getItem("searchProcurementDriver")
      // ) {
      //   return;
      // }

      // if (this.dateRange.startDate && this.dateRange.endDate) {
      //   this.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD");
      //   this.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
      // }
      if (dateRangeVal) {
        localStorage.setItem("ProtartdateFilter", JSON.stringify(dateRangeVal));

        url.push(`startDate=${this.startDate}`);
        url.push(`endDate=${this.endDate}`);
      } else if (this.startDate.length > 0) {
        url.push(`startDate=${this.startDate}`);
        url.push(`endDate=${this.endDate}`);
      }

      if (this.subJobType) {
        localStorage.setItem("subJobTypePro", JSON.stringify(this.subJobType));
        url.push(`jobType=${this.subJobType.value}`);
      }

      if (this.search.trim().length > 0 && this.searchTypes) {
        localStorage.setItem("searchProcurement", this.search);
        localStorage.setItem(
          "searchProcurementType",
          JSON.stringify(this.searchTypes)
        );

        url.push(`searchType=${this.searchTypes.abbr}`);
        url.push(`searchText=${this.search}`);
      } else if (this.searchTypes) {
        // localStorage.removeItem("searchProcurement");
        // localStorage.removeItem("searchProcurementType");
        // url.push(`searchType=${this.searchTypes.abbr}`);
        // this.search = "";
      }

      if (this.driverSearch.trim().length > 0 && this.driverSearchTypes) {
        this.isDashboard = false;
        localStorage.setItem("searchProcurementDriver", this.driverSearch);
        localStorage.setItem(
          "searchProcurementDriverType",
          JSON.stringify(this.driverSearchTypes)
        );

        url.push(`searchTextTwo=${this.driverSearch}`);
        url.push(`searchTypeTwo=${this.driverSearchTypes.abbr}`);
      } else if (this.driverSearchTypes) {
        // this.isDashboard = true;
        // localStorage.removeItem("searchProcurementDriver");
        // localStorage.removeItem("searchProcurementDriverType");
        // url.push(`searchTypeTwo=${this.driverSearchTypes.abbr}`);
        // this.driverSearch = "";
      }

      let finalUrl = "/dmsAdmin/newjobList?";
      if (url.length > 0) {
        let params = url.join("&");
        finalUrl = finalUrl + params;
      } else return "";

      this.loading = true;
      this.paginationURL = finalUrl;
      this.getJobListCount();
    },
    // searchByType() {
    //   if (
    //     (this.searchTypes && this.search.trim().length > 0) ||
    //     this.select.abbr ||
    //     this.startDate ||
    //     this.search ||
    //     this.subJobType ||
    //     this.driverSearch
    //   ) {
    //     this.search = "";
    //     this.searchAnything();
    //   }
    // },
    // searchByDriverType() {
    //   if (
    //     (this.driverSearchTypes && this.driverSearch.trim().length > 0) ||
    //     this.select.abbr ||
    //     this.startDate ||
    //     this.subJobType ||
    //     this.search
    //   ) {
    //     this.driverSearch = "";
    //     this.driverSearchAnything();
    //   }
    // },

    refreshOnComment() {
      this.componentKey++;
    },
    refreshBid(event) {
      this.componentKey++;

      // for (var i = 0; i < this.items.length; i++) {
      //   if (event.jobId === this.items[i].jobId) {
      //     if (event.jobModel) {
      //       this.items[i].isAcknowedged = true;
      //       this.items[i].isTransporterJob = true;
      //       this.items[i].jobModel = event.jobModel;
      //     } else {
      //       this.items[i].isAcknowedged = true;
      //       this.items[i].isDriverJob = true;
      //     }
      //   }
      // }
    },
    startSocket: function () {
      var socket = io(this.constants.apiUrl, {
        query: "adminToken=" + localStorage.getItem(StorageKeys.SessionKey),
        forceNew: true,
      });
      socket.on("connect", () => {});
      socket.on("unassignedJob", (data) => {
        this.newUnassigned = this.newUnassigned + 1;
        new Audio(
          "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
        ).play();
      });
      socket.on("assignedDriver", (data) => {
        this.newInprogress = this.newInprogress + 1;
        new Audio(
          "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
        ).play();
      });
      socket.on("completeAssignment", (data) => {
        this.newCompleted = this.newCompleted + 1;
        new Audio(
          "https://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
        ).play();
      });
    },
    open3(val, index) {
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }
      if (!this.cancelType) {
        this.x.error = "Please select cancellation reason before moving ahead";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return false;
      }
      if (this.cancelType.value == "Other’s") {
        if (!this.cancelComments.trim()) {
          this.x.error = "Please enter cancellation reason before moving ahead";
          setTimeout(() => {
            this.x.error = "";
          }, 3000);
          return false;
        }
      }
      this.dialog3 = true;
      this.dialog2 = false;
      this.cancelTitle1 = "Cancellation Message";
      this.cancelMessage1 = "Your job has been cancelled!";
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    cancel3() {
      this.resolve(false);
      this.dialog3 = false;
    },
    open2(val, index) {
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }
      this.currentEnquiry = val;
      this.currentIndex = index;

      this.cancelComments = "";
      this.cancelType = "";

      this.dialog2 = true;
      this.cancelTitle = "Cancel Job";
      this.cancelMessage = "On clicking YES this job will be cancelled";

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree2() {
      this.resolve(true);

      this.cancelJob(this.currentEnquiry, this.currentStatus);
      this.dialog2 = false;
    },
    cancel2() {
      this.resolve(false);
      this.dialog2 = false;
    },
    cancelJob(value, status) {
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }

      this.loading = true;
      let url = "/dmsAdmin/cancelJob";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        jobId: this.items[this.currentIndex].jobId.toString(),
        type: 1,
      };
      if (this.cancelType.value) {
        if (this.cancelType.value == "Other’s") {
          body.cancellationReason =
            this.cancelType.value + " - " + this.cancelComments.trim();
        } else {
          body.cancellationReason = this.cancelType.value;
        }
      }

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.items[this.currentIndex].jobStatus = -1;
          this.dialog3 = false;

          this.loading = false;
        },
        (error) => {
          this.loading = false;

          this.x.error = error.response.data.message;
          setTimeout(function () {
            this.x.error = "";
          }, 3000);
        }
      );
    },

    open(val, index, workingCountry) {
      console.log(workingCountry);
      this.getUserList(workingCountry);
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }
      this.currentEnquiry = val;
      this.currentIndex = index;
      this.selectedUserId = "";

      this.dialog = true;
      this.title = "Acknowledge Job";
      this.message = "Click Yes to acknowledge this job";
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      if (this.selectedUserId.trim()) {
        this.updateStatus(this.currentEnquiry, this.currentStatus);
        this.dialog = false;
      } else {
        this.x.error = "Please provide User Name for Procured";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return false;
      }
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
    updateStatus(value, status) {
      this.loading = true;
      let url = "/dmsAdmin/acknowledgeProcurement";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        jobId: this.items[this.currentIndex].jobId.toString(),
      };
      if (this.selectedUserId) {
        body.procurementExec = this.selectedUserId.trim();
      }
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.identify(localStorage.getItem("email"));
          this.track("Job Acknowledge", {
            "Job Id": value.jobId.toString(),
            "Company Name/Customer Name":
              value.companyName || value.customer || value.customerEmail,
            "No. of assignmnets": value.numberOfAssignmentsRequired,
            "Job Start Date": moment.unix(value.startDate).format(),
            "Working Country": value.workingCountry,
            "Selling Price": value.jobPrice,
          });
          this.items[this.currentIndex].isAcknowedged = true;
          this.items[this.currentIndex].jobStatus = 4;
          this.loading = false;
        },
        (error) => {
          this.loading = false;

          this.x.error = error.response.data.message;
        }
      );
    },
    goToProfile(jobId) {
      this.$router.push(`/jobs/${jobId}`);
    },
    getTime(time) {
      return moment.unix(time).format("DD/MM/YYYY");
    },
    getTime3(date) {
      return moment.utc(date).format("ll");
    },
    getTime4(date) {
      return moment(date).format("ll LT");
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "Active";
        case 2:
          return "Completed";
        case 3:
          return "Customer Rate Accepted";
        case 4:
          return "Finding Drivers";
        case 5:
          return "Driver Assigned";
        case 6:
          return "In-Transit";
        case 7:
          return "Shipment Offloaded & POD Awaited";
        case 8:
          return "Payment Pending";
        case 9:
          return "Payment Done";
        case 10:
          return "Low Credit Limit";

        case -1:
          return "Cancelled";
        default:
          return "Unknown";
      }
    },
    checkLoading(event) {
      if (event) {
        this.$emit("job-load", true);
      } else {
        this.$emit("job-load", false);
      }
    },

    // searchAnything() {
    //   if (!navigator.onLine) {
    //     this.loading = false;
    //     this.x.error = "Please check your internet connection";
    //     setTimeout(() => {
    //       this.x.error = "";
    //     }, 3000);
    //     return;
    //   }
    //   if (this.search == localStorage.getItem("searchProcurement")) {
    //     return;
    //   }
    //   if (this.dateRange.startDate && this.dateRange.endDate) {
    //     this.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD");
    //     this.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
    //   }
    //   let timezone = momentTz.tz.guess();
    //   if (this.search.trim().length > 0) {
    //     localStorage.setItem("searchProcurement", this.search);
    //     localStorage.setItem(
    //       "searchProcurementType",
    //       JSON.stringify(this.searchTypes)
    //     );
    //     this.jobStatus = this.tabStatus;
    //     let url = `/dmsAdmin/newjobList?workingCountry=${this.workingCountry}&status=${this.tabStatus}&isSearch=true&searchText=${this.search}&searchType=${this.searchTypes.abbr}`;
    //     if (this.startDate) {
    //       url = url + `&startDate=${this.startDate}&endDate=${this.endDate}`;
    //     }
    //     if (this.subJobType) {
    //       url = url + `&jobType=${this.subJobType.value}`;
    //     }
    //     if (this.driverSearch) {
    //       url =
    //         url +
    //         `&driverSearchText=${this.driverSearch}&driverSearchType=${this.driverSearchTypes.abbr}`;
    //     }
    //     this.loading = true;
    //     this.paginationURL = url;
    //   } else {
    //     localStorage.removeItem("searchProcurement");
    //     localStorage.removeItem("searchProcurementType");
    //     this.jobStatus = this.tabStatus;
    //     let url = `/dmsAdmin/newjobList?workingCountry=${this.workingCountry}&status=${this.tabStatus}&isSearch=true&searchType=${this.searchTypes.abbr}`;
    //     if (this.startDate) {
    //       url = url + `&startDate=${this.startDate}&endDate=${this.endDate}`;
    //     }
    //     if (this.subJobType) {
    //       url = url + `&jobType=${this.subJobType.value}`;
    //     }
    //     if (this.driverSearch) {
    //       url =
    //         url +
    //         `&driverSearchText=${this.driverSearch}&driverSearchType=${this.driverSearchTypes.abbr}`;
    //     }
    //     this.loading = true;
    //     this.paginationURL = url;
    //   }
    // },
    // driverSearchAnything() {
    //   if (!navigator.onLine) {
    //     this.loading = false;
    //     this.x.error = "Please check your internet connection";
    //     setTimeout(() => {
    //       this.x.error = "";
    //     }, 3000);
    //     return;
    //   }
    //   if (
    //     this.driverSearch == localStorage.getItem("searchProcurementDriver")
    //   ) {
    //     return;
    //   }
    //   if (this.dateRange.startDate && this.dateRange.endDate) {
    //     this.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD");
    //     this.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
    //   }
    //   let timezone = momentTz.tz.guess();
    //   if (this.driverSearch.trim().length > 0) {
    //     this.isDashboard = false;
    //     localStorage.setItem("searchProcurementDriver", this.driverSearch);
    //     localStorage.setItem(
    //       "searchProcurementDriverType",
    //       JSON.stringify(this.driverSearchTypes)
    //     );
    //     this.jobStatus = this.tabStatus;
    //     let url = `/dmsAdmin/newjobList?workingCountry=${this.workingCountry}&status=${this.tabStatus}&isSearch=true&driverSearchText=${this.driverSearch}&driverSearchType=${this.driverSearchTypes.abbr}`;
    //     if (this.startDate) {
    //       url = url + `&startDate=${this.startDate}&endDate=${this.endDate}`;
    //     }
    //     if (this.subJobType) {
    //       url = url + `&jobType=${this.subJobType.value}`;
    //     }
    //     if (this.search) {
    //       url =
    //         url +
    //         `&searchText=${this.search}&searchType=${this.searchTypes.abbr}`;
    //     }
    //     this.loading = true;
    //     this.paginationURL = url;
    //   } else {
    //     this.isDashboard = true;
    //     localStorage.removeItem("searchProcurementDriver");
    //     localStorage.removeItem("searchProcurementDriverType");
    //     this.jobStatus = this.tabStatus;
    //     let url = `/dmsAdmin/newjobList?workingCountry=${this.workingCountry}&status=${this.tabStatus}&isSearch=true&driverSearchType=${this.driverSearchTypes.abbr}`;
    //     if (this.startDate) {
    //       url = url + `&startDate=${this.startDate}&endDate=${this.endDate}`;
    //     }
    //     if (this.subJobType) {
    //       url = url + `&jobType=${this.subJobType.value}`;
    //     }
    //     if (this.select.length) {
    //       url = url + `&status=${JSON.stringify(this.select)}`;
    //     }
    //     if (this.search) {
    //       url =
    //         url +
    //         `&searchText=${this.search}&searchType=${this.searchTypes.abbr}`;
    //     }

    //     this.loading = true;
    //     this.paginationURL = url;
    //   }
    // },

    // async filterByJobStatus(val) {
    //   if (!navigator.onLine) {
    //     this.loading = false;
    //     this.x.error = "Please check your internet connection";
    //     setTimeout(() => {
    //       this.x.error = "";
    //     }, 3000);
    //     return;
    //   }
    //   if (this.dateRange.startDate && this.dateRange.endDate) {
    //     this.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD");
    //     this.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
    //   }
    //   this.newUnassigned = 0;
    //   this.newInprogress = 0;
    //   this.newCompleted = 0;
    //   let timezone = momentTz.tz.guess();
    //   localStorage.setItem("TabStatus", val);
    //   this.tabStatus = val;
    //   this.paginationURL = "";
    //   let url = `/dmsAdmin/newjobList?workingCountry=${this.workingCountry}&isSearch=true&status=${val}`;
    //   if (this.startDate) {
    //     url = url + `&startDate=${this.startDate}&endDate=${this.endDate}`;
    //   }
    //   if (this.search) {
    //     url =
    //       url +
    //       `&searchText=${this.search}&searchType=${this.searchTypes.abbr}`;
    //   }
    //   if (this.subJobType) {
    //     url = url + `&jobType=${this.subJobType.value}`;
    //   }
    //   if (this.driverSearch) {
    //     url =
    //       url +
    //       `&driverSearchText=${this.driverSearch}&driverSearchType=${this.driverSearchTypes.abbr}`;
    //   }
    //   this.paginationURL = url;
    // },
    // async jobTypeFilter() {
    //   if (!navigator.onLine) {
    //     this.loading = false;
    //     this.x.error = "Please check your internet connection";
    //     setTimeout(() => {
    //       this.x.error = "";
    //     }, 3000);
    //     return;
    //   }
    //   let timezone = momentTz.tz.guess();
    //   localStorage.setItem("subJobTypePro", JSON.stringify(this.subJobType));
    //   this.jobStatus = this.tabStatus;
    //   let url = `/dmsAdmin/newjobList?workingCountry=${this.workingCountry}&status=${this.tabStatus}&isSearch=true&jobType=${this.subJobType.value}`;
    //   if (this.startDate) {
    //     url = url + `&startDate=${this.startDate}&endDate=${this.endDate}`;
    //   }
    //   if (this.search) {
    //     url =
    //       url +
    //       `&searchText=${this.search}&searchType=${this.searchTypes.abbr}`;
    //   }
    //   if (this.driverSearch) {
    //     url =
    //       url +
    //       `&driverSearchText=${this.driverSearch}&driverSearchType=${this.driverSearchTypes.abbr}`;
    //   }
    //   this.loading = true;
    //   this.paginationURL = url;
    // },
    reset() {
      if (localStorage.getItem("TabStatus") == "In-progress" || "Completed") {
        this.driverSearchItems = [
          {
            status: "Company Name/Customer Name",
            abbr: "companyName/customerName",
          },
          { status: "Job ID", abbr: "jobId" },
          { status: "Assignment ID", abbr: "assignmentId" },
          { status: "Driver ID", abbr: "id" },
          {
            status: "Driver Name",
            abbr: "name",
          },
          { status: "Truck Number", abbr: "truckNumber" },
        ];

        this.searchItems = [
          {
            status: "Company Name/Customer Name",
            abbr: "companyName/customerName",
          },
          { status: "Job ID", abbr: "jobId" },
          { status: "Assignment ID", abbr: "assignmentId" },
          { status: "Driver ID", abbr: "id" },
          {
            status: "Driver Name",
            abbr: "name",
          },
          { status: "Truck Number", abbr: "truckNumber" },
        ];
      }
      if (
        localStorage.getItem("TabStatus") == "Unassigned" ||
        !localStorage.getItem("TabStatus")
      ) {
        this.driverSearchItems = [
          {
            status: "Company Name/Customer Name",
            abbr: "companyName/customerName",
          },
          { status: "Job ID", abbr: "jobId" },
          { status: "Assignment ID", abbr: "assignmentId" },
        ];
        this.searchItems = [
          {
            status: "Company Name/Customer Name",
            abbr: "companyName/customerName",
          },
          { status: "Job ID", abbr: "jobId" },
          { status: "Assignment ID", abbr: "assignmentId" },
        ];
      }
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.xerror = "";
        }, 3000);
        return;
      }
      localStorage.removeItem("subJobTypePro");
      localStorage.removeItem("ProtartdateFilter");
      localStorage.removeItem("ProenddateFilter");
      localStorage.removeItem("searchProcurement");
      localStorage.removeItem("searchProcurementType");
      localStorage.removeItem("searchProcurementDriver");
      localStorage.removeItem("searchProcurementDriverType");
      localStorage.removeItem("paginationUrlProcure");
      this.jobStatus = this.tabStatus;
      this.startDate = "";
      this.select = { status: "", abbr: "" };
      this.selects = { status: "", abbr: "" };
      this.status = "";
      this.dateRange2 = null;
      this.subJobType = "";
      this.executive = "";
      this.search = "";
      this.driverSearchTypes = "";
      this.isDashboard = true;
      this.driverSearch = "";
      this.searchTypes = "";
      this.paginationURL = `/dmsAdmin/newjobList?workingCountry=${this.workingCountry}&isSearch=true&status=${this.jobStatus}`;
      this.getJobListCount();
      return;
    },
    getJobListCount() {
      let v = {};
      this.paginationLoading = true;
      let Url = "/dmsAdmin/newjobListCount";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      this.axios.defaults.headers.common["authorization"] = `bearer ${token}`;

      let temp_url = this.paginationURL;
      Url = "/dmsAdmin/newjobListCount?" + temp_url.split("?")[1];
      let body = {};

      v = this.axios.get(this.constants.apiUrl + Url, body).then(
        (response) => {
          this.paginationLoading = false;
          this.jobCount = response.data.data;
          let countData = this.jobCount;
          this.totalFindingDrivers = countData.totalFindingDrivers;
          this.totalDriverAssigned = countData.totalDriverAssigned;
          this.totalJob = countData.totalData;
          this.totalPages = Math.ceil(this.totalJob / 15);
        },
        (error) => {
          this.paginationLoading = false;
        }
      );

      return v;
    },

    getLocation(...val) {
      return val ? val.reduce((val, curr) => val + ", " + curr) : "";
    },
    async callback({
      jobInfo,
      totalInquiry,
      totalData,
      totalDriverAssigned,
      totalFindingDrivers,
    }) {
      // this.getJobListCount();

      this.items = jobInfo;
      this.totalInquiry = totalInquiry;
      this.pagination.rowsPerPage = 15;
      this.$emit("job-load", false);
      window.scrollTo(0, 0);
      this.loading = false;
    },
    submit() {
      this.loading = true;
      let url = "/dmsAdmin/sendJobToTransport";
      // delete this.axios.defaults.headers.common["token"];
      // let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          // authorization: `bearer ${token}`,
        },
      };
      let body = {};

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.items = response.data.data;

          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.x.error = "Failed to update status";
        }
      );
    },
  },
};
</script>

<style lang="scss">
.card--raised {
  border: 1px solid orange !important;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  font-size: 16px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.viewDetail {
  cursor: pointer;
}
.job-detail {
  margin: auto 0;
}
.theme--light.menuable__content__active {
  min-width: 290px !important;
}
.text-center {
  text-align: center !important;
}

.date-picker {
  min-width: 100%;
}

.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}

.cancel-btn {
  text-decoration: none;
  color: #ffffff;
  border: 2px solid red;
  padding: 2px;
  margin-top: 5px;
  background-color: #f38282;
  border-radius: 5px;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.assign-driver-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #bebebe;
  border: 2px solid grey;
  padding: 2px 5px;
  border-radius: 5px;
  width: max-content !important;
}
.tooltip-op-comment {
  /* left: calc(100% - 20.2%) !important; */
  max-width: 15%;
  opacity: 1 !important;
}
.p-16 {
  padding: 0 16px !important;
}
.p-b-0 {
  padding-bottom: 0 !important;
}
.p-t-0 {
  padding-top: 0 !important;
}
.minHeight {
  min-height: 300px;
}
.colorchange {
  background-color: #fae3e3 !important;
}
.colorchangeyellow {
  background-color: #ffffe0 !important;
}
.spacerclass {
  max-height: 550px !important;
}
.m-t-10 {
  margin-top: 10px;
}

.filter {
  padding: 20px;
  margin: 0 auto;
}
.reset {
  margin-top: 10px;
}
</style>
