<template>
  <div class="log">
    <v-dialog v-model="open" persistent max-width="40%">
      <v-form ref="form" onSubmit="return false;">
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title>Select Country</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="close()">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="loading"
          ></v-progress-linear>
          <v-spacer class="p--25">
            <v-card-text class="nospace">
              <v-layout>
                <v-flex md12
                  >Select the country you want to proceed with</v-flex
                >
                <v-flex md12>
                  <v-radio-group
                    class="mt-0 pt-0"
                    v-model="selectedCountry"
                    row
                    :mandatory="true"
                  >
                    <div class="heading">
                      <v-layout>
                        <v-radio
                          v-for="(data, i) in countries"
                          :key="i"
                          row
                          :label="data.value"
                          :value="data.value"
                          color="green"
                          class="col-md-6"
                        >
                        </v-radio>
                      </v-layout>
                    </div>
                  </v-radio-group>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <!-- <ErrorBox :error="x.error"/>-->
              <v-spacer></v-spacer>
              <v-btn color="orange darken-1" flat @click="proceed()"
                >Proceed</v-btn
              >
            </v-card-actions>
            <v-snackbar
              :timeout="3000"
              bottom
              color="red darken-2"
              v-model="x.error"
              class="white--text"
              v-if="x.error"
              >{{ x.error }}</v-snackbar
            >
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { StorageKeys } from "../../constants/constants";
import { EventBus } from "../../event-bus.js";
export default {
  data() {
    return {
      loading: false,
      x: {
        error: null,
      },
      radios: "All",
      countries: [],
      workingCountries: [],
      loading: false,
      selectedCountry: "",
    };
  },
  props: {
    open: Boolean,
    type: String,
  },
  created() {},

  watch: {
    open(val) {
      if (val) {
        this.getCountries();
      }
    },
  },

  methods: {
    getCountries() {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.countries = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.countries.push(key);
          }
        });
        setTimeout(() => {
          this.selectedCountry = this.countries[0].value;
        }, 200);
      }
    },
    close() {
      this.$emit("closeSelectCountry");
    },
    proceed() {
      EventBus.$emit("updateCountryGlobally", {
        country: this.selectedCountry,
        redirect: this.type,
      });
    },
    getData() {
      this.loading = true;
      let Url = "/dmsAdmin/getUserEmailRoles";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        userId: this.detail.userId.toString(),
      };
      this.axios.post(this.constants.apiUrl + Url, body, config).then(
        (response) => {
          if (response.data.statusCode == 200) {
            this.radios = response.data.data[0].emailType;
            this.JobStatus = response.data.data[0].jobStatusForEmail;
            this.AssignmentStatus =
              response.data.data[0].assignmentStatusForEmail;
            this.ReceiveEmails = response.data.data[0].isReceiveEmail;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.x.error = error.response.data.message;
        }
      );
    },
    emailNoyify() {
      this.checkStatus();
      this.loading = true;
      let Url = "/dmsAdmin/editUserEmailRoles";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        userId: this.detail.userId.toString(),
        emailType: this.radios,
        jobStatusForEmail: this.JobStatus,
        assignmentStatusForEmail: this.AssignmentStatus,
        isReceiveEmail: this.ReceiveEmails,
      };
      this.axios.post(this.constants.apiUrl + Url, body, config).then(
        (response) => {
          if (response.data.statusCode == 200) {
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.x.error = error.response.data.message;
        }
      );
    },

    checkStatus() {
      if (this.radios == "Summary") {
        this.JobStatus = false;
        this.AssignmentStatus = false;
      }
      if (this.ReceiveEmails == false) {
        this.JobStatus = false;
        this.radios = "All";
        this.AssignmentStatus = false;
      }
    },
  },
};
</script>
<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.switch-permission-type {
  width: 25%;
  display: inline-block;
  float: left;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
</style>
